<template>
  <div>
    <date-picker date v-model="date" value-type="format" format="YYYY-MM-DD" @input="dateChanged" placeholder="Select date here">
      <template v-slot:input="slotProps">
        <h2 class="font-weight-light text-nowrap cursor-pointer" v-bind="slotProps.props" v-on="slotProps.events">
          {{ title }}
        </h2>
      </template>
      <template  v-slot:icon-clear>
        <span></span>
      </template>
      <template  v-slot:icon-calendar>
        <span></span>
      </template>
    </date-picker>
  </div>
</template>

<script>
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'

export default {
  components: { DatePicker },
  data() {
    return {
      date: null
    }
  },
  props: {
    value: {
      type: null
    },
    title: {
      type: String
    }
  },
  mounted() {
    this.date = this.value
  },
  methods: {
    dateChanged() {
      this.$emit("new-date", this.date)
    }
  }
}
</script>